import React from "react";
import ReactDatetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { dateFormat, timeFormat } from "../../config.json";

const Datetime = ({
  name,
  label,
  value,
  dateFormatProvided,
  timeFormatProvided,
  error,
  viewMode,
  ...rest
}) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <ReactDatetime
        name={name}
        value={value}
        dateFormat={dateFormatProvided ? dateFormatProvided : dateFormat}
        timeFormat={timeFormatProvided ? timeFormatProvided : timeFormat}
        inputProps={{ name: name }}
        viewMode={viewMode}
        {...rest}
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Datetime;
