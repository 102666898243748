import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";

class IncidentsTable extends Component {
  columns = [
    {
      path: "shortDescription",
      label: "Description",
      content: incident => (
        <Link to={`/incidents/${incident.id}`}>
          {incident.shortDescription}
        </Link>
      )
    },
    { path: "incidentDateTime", label: "Date & Time" },
    { path: "incidentType.name", label: "Category" },
    {
      path: "reporter.name",
      label: "Reporter"
    },
    {
      path: "community.name",
      label: "Community"
    }
  ];

  render() {
    const { data, onSort, sortColumn } = this.props;
    return (
      <Table
        columns={this.columns}
        data={data}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default IncidentsTable;
