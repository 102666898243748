import http from "./httpService";

const apiEndpoint = "users";

function apiEndPointWithId(id) {
  return `${apiEndpoint}/${id}`;
}

export function getActiveUsersByCurrentUser() {
  return http.get(apiEndpoint);
}

export function getAllUsersByCurrentUser() {
  return http.get(`${apiEndpoint}/all`);
}

export function getUser(userId) {
  return http.get(apiEndPointWithId(userId));
}

export function getMyProfile() {
  return http.get(`${apiEndpoint}/profile`);
}

export function saveUser(user) {
  if (user.id) {
    const body = { ...user };
    delete body.password;
    delete body.confirmPassword;
    return http.put(apiEndpoint, body);
  }

  return http.post(apiEndpoint, user);
}

export function updatePassword(passwordVO) {
  return http.post(`${apiEndpoint}/profileP`, passwordVO);
}
