import http from "./httpService";

const apiEndpoint = "incidents";

function apiEndPointWithId(id) {
  return `${apiEndpoint}/${id}`;
}

export function getIncidents() {
  console.log(apiEndpoint);
  return http.get(apiEndpoint);
}

export function getIncidentsByCriteria(communityId, startDate, endDate) {
  return http.get(apiEndpoint + `/${communityId}/${startDate}/${endDate}`);
}

export function getIncident(incidentId) {
  return http.get(apiEndPointWithId(incidentId));
}

export function saveIncident(incident, images) {
  console.log(incident);
  return incident.id > 0
    ? http.put(apiEndpoint, incident)
    // : http.postWithFiles("incidents/rn", incident, images);    // For RN
  : http.postWithFiles(apiEndpoint, incident, images);
}

export function saveImagesForIncident(dirName, images) {
  return http.post("files/upload-multi", images);
}

export function deleteIncident(incidentId) {
  return http.delete(apiEndPointWithId(incidentId));
}
