import React, { Component } from "react";
import authService from "../services/authService";

class LoginSuccess extends Component {
  handleLogout = () => {
    authService.logout();
    window.location = "/";
  };
  render() {
    return (
      <div>
        Login successfull!
        <div>
          <a className="nav-link" href="/logout" onClick={this.handleLogout}>
            Sign out
          </a>
        </div>
      </div>
    );
  }
}

export default LoginSuccess;
