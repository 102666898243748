import React, { Component } from "react";
import Table from "../common/table";
import { Link } from "react-router-dom";

class CategoriesTable extends Component {
  columns = [
    {
      path: "name",
      label: "Name",
      content: user => <Link to={`/users/${user.id}`}>{user.name}</Link>
    },
    {
      path: "active",
      label: "Active/Locked",
      content: user => <h6>{user.isActive ? "Active" : "Locked"}</h6>
    }
  ];

  render() {
    const { categories, sortColumn, onSort } = this.props;
    
    return (
      <Table
        columns={this.columns}
        data={categories}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default CategoriesTable;
