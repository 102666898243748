import React, { Component } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType);
class FileUpload extends Component {
  render() {
    return (
      <div className="mb-2">
        <label>{this.props.label}</label>
        <FilePond
          allowMultiple={true}
          maxFiles={10}
          acceptedFileTypes={this.props.acceptedFileTypes}
          labelFileTypeNotAllowed={this.props.labelFileTypeNotAllowed}
          onupdatefiles={this.props.raiseFilesUpdate}
        />
      </div>
    );
  }
}

export default FileUpload;
