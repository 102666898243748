import React, { Component } from "react";
import Datetime from "./datetime";
import Input from "./input";
import Joi from "joi-browser";
import Moment from "moment";
import Radio from "./radio";
import Select from "./select";
import Textarea from "./textarea";
import { datetimeFormat } from "../../config.json";
import Checkbox from "./checkbox";

class Form extends Component {
  state = {
    data: {},
    errors: {},
    disableOnClick: false
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    console.log(errors);
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({
      errors: errors || {},
      disableOnClick: errors ? false : true
    });
    if (errors) return;

    this.doSubmit();
  };

  renderButton(label, icon = "floppy-o") {
    return (
      <button
        disabled={(this.validate() ? true : false) || this.state.disableOnClick}
        className="btn btn-success"
      >
        <span className={`fa fa-${icon} mr-2`} />
        {label}
      </button>
    );
  }

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    errorMessage && console.log(errorMessage);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.type === "checkbox" ? input.checked : input.value;

    this.setState({ data, errors });
  };

  renderInput(name, label, isDisabled = false, type = "text") {
    const { data, errors } = this.state;

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={isDisabled}
      />
    );
  }

  renderTextarea(name, label, rows, cols) {
    const { data, errors } = this.state;

    return (
      <Textarea
        name={name}
        value={data[name]}
        label={label}
        rows={rows}
        cols={cols}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderSelect(name, label, options) {
    const { data, errors } = this.state;

    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderCheck(name, label) {
    const { data, errors } = this.state;

    return (
      <Checkbox
        name={name}
        value={data[name]}
        checked={data[name]}
        error={errors[name]}
        label={label}
        onChange={this.handleChange}
      />
    );
  }

  handleChangeDatetime = (moment, name) => {
    const errors = { ...this.state.errors };
    let errorMessage = "";
    try {
      errorMessage =
        moment instanceof Moment // is moment is instanceof String, then invalid
          ? ""
          : "'Date & Time' is invalid";
    } catch (ex) {
      errorMessage = "'Date & Time' is invalid";
    }
    if (errorMessage) {
      errors[name] = errorMessage;
      this.setState({ errors });
    } else {
      delete errors[name];

      const data = { ...this.state.data };
      data[name] = moment.format(datetimeFormat);
      this.setState({ data, errors });
    }
  };
  renderDatetime(name, label, viewMode = "time") {
    const { data, errors } = this.state;

    return (
      <Datetime
        name={name}
        value={data[name]}
        label={label}
        viewMode={viewMode}
        onChange={moment => this.handleChangeDatetime(moment, name)}
        error={errors[name]}
      />
    );
  }

  handleRadiaChange = async ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
  renderRadio(name, label, options, isVertical = false) {
    const { data, errors } = this.state;

    return (
      <Radio
        name={name}
        value={data[name]}
        label={label}
        options={options}
        isVertical={isVertical}
        onChange={this.handleRadiaChange}
        error={errors[name]}
      />
    );
  }
}

export default Form;
