import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../services/authService";

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  // console.log(path);
  return (
    <Route
      {...rest}
      render={props => {
        if (!auth.getCurrentUserId()) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          );
        }
        if (
          auth.getCurrentUserRoleRank() > 10 &&
          (path !== "/incidents/:id" &&
            path !== "/checklists/start" &&
            path !== "/checklists/end" &&
            path !== "/myProfile/:id" &&
            path !== "/changePassword/:userId" &&
            path !== "/incidents")
        ) {
          return (
            <Redirect
              to={{
                pathname: "/incidents",
                state: { from: props.location }
              }}
            />
          );
        }
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
