import React, { Component } from "react";
import ChecklistForm from "./checklistForm";

class ChecklistEnd extends Component {
  render() {
    return (
      <ChecklistForm
        label="Shift End Checklist"
        isShiftStartChecklist={false}
        {...this.props}
      />
    );
  }
}

export default ChecklistEnd;
