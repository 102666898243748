import React, { Component } from "react";
import Radio from "../common/radio";
import { Link } from "react-router-dom";
import Moment from "moment";
import IncidentsTable from "./incidentsTable";
import Pagination from "../common/pagination";
import { getCurrentUserRoleRank } from "../../services/authService";
import { getAllCommunities } from "../../services/communityService";
import { getIncidentsByCriteria } from "../../services/incidentService";
import {
  populateMonthSelectors,
  populateStartAndEndDateBySelectedMonth
} from "../../utils/datetimeUtil.js";
import { defaultTblPageSize } from "../../config.json";
import { paginate } from "../../utils/datatableUtils";
import _ from "lodash";

class Incidents extends Component {
  state = {
    incidents: [],
    currentPage: 0,
    pageSize: defaultTblPageSize,
    searchQuery: "",
    selectedIncidentCode: null,
    selectedCommunityId: 0,
    selectedMonthId: new Date().getMonth(),
    sortColumn: { path: "incidentDateTime", order: "desc" }
  };

  async componentWillMount() {
    const allCommunities = await this.populateCommunities();
    const filterMonthsArr = populateMonthSelectors();
    const {
      selectedStartDate,
      selectedEndDate
    } = populateStartAndEndDateBySelectedMonth(
      this.state.selectedMonthId,
      filterMonthsArr
    );

    const { data } = await getIncidentsByCriteria(
      this.state.selectedCommunityId,
      selectedStartDate,
      selectedEndDate
    );
    // for date sorting
    const incidents = data.map(incident => {
      incident.incidentDateTimeObj = Moment(
        incident.incidentDateTime,
        "DD-MMM-YYYY HH:mm"
      ).toDate();
      return incident;
    });

    this.setState({
      allCommunities,
      filterMonthsArr,
      incidents,
      selectedStartDate,
      selectedEndDate
    });
  }

  async populateCommunities() {
    const { data } = await getAllCommunities();
    return [{ id: 0, name: "All" }, ...data];
  }

  handleCommunityChange = async ({ currentTarget: input }) => {
    const { selectedStartDate, selectedEndDate } = this.state;
    const selectedCommunityId = Number.parseInt(input.value);

    const { data } = await getIncidentsByCriteria(
      selectedCommunityId,
      selectedStartDate,
      selectedEndDate
    );
    const incidents = data.map(incident => {
      incident.incidentDateTimeObj = Moment(
        incident.incidentDateTime,
        "DD-MMM-YYYY HH:mm"
      ).toDate();
      return incident;
    });

    this.setState({
      incidents,
      selectedCommunityId,
      currentPage: 0
    });
  };

  handleMonthChange = async ({ currentTarget: input }) => {
    const selectedMonthId = Number.parseInt(input.value);

    const {
      selectedStartDate,
      selectedEndDate
    } = populateStartAndEndDateBySelectedMonth(
      selectedMonthId,
      this.state.filterMonthsArr
    );

    const { data } = await getIncidentsByCriteria(
      this.state.selectedCommunityId,
      selectedStartDate,
      selectedEndDate
    );
    const incidents = data.map(incident => {
      incident.incidentDateTimeObj = Moment(
        incident.incidentDateTime,
        "DD-MMM-YYYY HH:mm"
      ).toDate();
      return incident;
    });

    this.setState({
      incidents,
      selectedMonthId,
      selectedStartDate,
      selectedEndDate,
      currentPage: 0
    });
  };

  handleLike = incident => {
    const incidents = [...this.state.incidents];
    const index = incidents.indexOf(incident);
    incidents[index] = { ...incidents[index] };
    incidents[index].liked = !incidents[index].liked;
    this.setState({ incidents });
  };

  handlePageChange = event => {
    this.setState({ currentPage: event.page });
  };

  handleIncidentCodeSelect = incidentCode => {
    this.setState({
      selectedIncidentCode: incidentCode,
      searchQuery: "",
      currentPage: 0
    });
  };

  handleSearch = query => {
    this.setState({
      searchQuery: query,
      selectedIncidentCode: null,
      currentPage: 0
    });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      incidents: incidentsAll,
      sortColumn
    } = this.state;

    const incidentsSorted = _.orderBy(
      incidentsAll,
      [
        sortColumn.path === "incidentDateTime"
          ? "incidentDateTimeObj"
          : sortColumn.path
      ],
      [sortColumn.order]
    );

    const incidentsPerPage = paginate(incidentsSorted, currentPage, pageSize);
    return { totalCount: incidentsSorted.length, data: incidentsPerPage };
  };

  render() {
    const { pageSize, currentPage, sortColumn } = this.state;
    const { totalCount, data } = this.getPagedData();

    return (
      <React.Fragment>
        <div className="h1" style={{ color: "#005935" }}>
          <span className="fa fa-file-text mr-3" />
          Latest Incidents
        </div>
        {getCurrentUserRoleRank() > 10 && (
          <div className="d-flex">
            <Link to="/incidents/new" className="btn btn-success ml-auto mb-3">
              <span className="fa fa-plus-square mr-2" />
              Report New Incident
            </Link>
          </div>
        )}
        {getCurrentUserRoleRank() <= 10 && (
          <div className="row">
            <div className="col-6 mt-3">
              <Radio
                name={"communityId"}
                value={this.state.selectedCommunityId}
                label={"Community"}
                options={this.state.allCommunities}
                onChange={this.handleCommunityChange}
              />
            </div>
            <div className="col-6 mt-3">
              <Radio
                name={"id"}
                value={this.state.selectedMonthId}
                label={"Month"}
                options={this.state.filterMonthsArr}
                onChange={this.handleMonthChange}
              />
            </div>
          </div>
        )}
        <IncidentsTable
          data={data}
          sortColumn={sortColumn}
          onLike={this.handleLike}
          onDelete={this.handleDelete}
          onSort={this.handleSort}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
      </React.Fragment>
    );
  }
}

export default Incidents;
