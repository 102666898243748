import jwtDecode from "jwt-decode";
import http from "./httpService";

const apiEndpoint = "auth/login";
const tokenKey = "sec-tok";

http.setJwt(getJwt());

export async function login(username, password) {
  const response = await http.post(apiEndpoint, {
    username,
    password
  });
  const { tokenType, accessToken: jwt } = response.data;
  sessionStorage.setItem(tokenKey, tokenType === "Bearer" ? jwt : null);
}

export function loginWithJwt(jwt) {
  sessionStorage.setItem(tokenKey, jwt);
}

export function logout() {
  sessionStorage.removeItem(tokenKey);
}

export function getCurrentUserId() {
  try {
    const jwt = sessionStorage.getItem(tokenKey);
    const { USER_ID } = jwtDecode(jwt);
    return USER_ID;
  } catch (ex) {
    // console.log(ex);
    return null;
  }
}

export function getCurrentUserName() {
  try {
    const jwt = sessionStorage.getItem(tokenKey);
    const { NAME } = jwtDecode(jwt);
    return NAME;
  } catch (ex) {
    // console.log(ex);
    return null;
  }
}

export function getCurrentUserRoleRank() {
  try {
    const jwt = sessionStorage.getItem(tokenKey);
    const { ROLE_RANK } = jwtDecode(jwt);
    return ROLE_RANK;
  } catch (ex) {
    // console.log(ex);
    return null;
  }
}

export function getCurrentUserDetails() {
  try {
    const jwt = sessionStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    // console.log(ex);
    return null;
  }
}

export function getJwt() {
  return sessionStorage.getItem(tokenKey);
}

export default {
  login,
  logout,
  getCurrentUserId,
  getCurrentUserName,
  getCurrentUserRoleRank,
  getCurrentUserDetails,
  getJwt
};
