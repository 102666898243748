import React from "react";
import Joi from "joi-browser";
import Moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Form from "../common/form";
import Checkboxes from "../common/checkboxes";
import { datetimeFormat } from "../../config.json";
import {
  getCurrentUserId,
  getCurrentUserName,
  getCurrentUserRoleRank
} from "../../services/authService";
import { getActiveUsersByCurrentUser } from "../../services/userService";
import {
  getAllChecklistItems,
  logShiftChecklist
} from "../../services/checklistService";

class ChecklistForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: this.initIncidentData(),
      reporters1: [],
      reporters2: [],
      checklistItemVOsGen: [],
      checklistItemVOsVeh: [],
      errors: {}
    };
  }
  initIncidentData = () => {
    return {
      isShiftStartChecklist: this.props.isShiftStartChecklist,
      logDatetime: Moment(new Date()).format(datetimeFormat),
      reporterId1: getCurrentUserId(),
      reporterName1: getCurrentUserName(),
      reporterId2: 0,
      reporterName2: "",
      email: "",
      unit: "",
      odometer: 0
    };
  };

  schema = {
    isShiftStartChecklist: Joi.bool(),
    logDatetime: Joi.date()
      .required()
      .label("Date & Time"),
    reporterId1: Joi.number(),
    reporterName1: Joi.string()
      .required()
      .label("Driver 1"),
    reporterId2: Joi.number(),
    reporterName2: Joi.string()
      .allow("")
      .label("Driver 2"),
    unit: Joi.string()
      .required()
      .max(6)
      .label("Unit"),
    odometer: Joi.number()
      .required()
      .max(9999999)
      .label("Odometer"),
    commentGen: Joi.string()
      .allow("")
      .max(1000)
      .label("Missing/Broken Equipments"),
    commentVeh: Joi.string()
      .allow("")
      .max(1000)
      .label("Missing/Broken Vehicle Parts"),
    email: Joi.string()
      .allow("")
      .max(1000)
      .label("Email address to email the missing/broken vehicle parts"),
    checklistItemVOs: Joi.array(),
    checklistItemVOsGen: Joi.array(),
    checklistItemVOsVeh: Joi.array()
  };

  async componentWillMount() {
    const isUserSupervisor = getCurrentUserRoleRank() <= 10;

    const { reporters1, reporters2 } = await this.populateReporters();

    const {
      checklistItemVOsGen,
      checklistItemVOsVeh
    } = await this.populateChecklist();

    this.setState({
      reporters1: isUserSupervisor ? reporters1 : [],
      reporters2,
      checklistItemVOsGen,
      checklistItemVOsVeh,
      isUserSupervisor
    });
  }

  async populateReporters() {
    const { data } = await getActiveUsersByCurrentUser();
    const reporters1 = [...data];
    const reporters2 = [{ id: 0, name: "None" }, ...data];
    return { reporters1, reporters2 };
  }

  async populateChecklist() {
    const { data } = await getAllChecklistItems();
    let checklistItemVOsGen = [],
      checklistItemVOsVeh = [];
    for (const itemVO of data) {
      if (itemVO.type === "GEN") {
        checklistItemVOsGen.push(itemVO);
      } else if (itemVO.type === "VEH") {
        checklistItemVOsVeh.push(itemVO);
      }
    }
    return { checklistItemVOsGen, checklistItemVOsVeh };
  }

  handleEqChecklistItemChange = ({ currentTarget: input }) => {
    let items = [...this.state.checklistItemVOsGen];

    const index = items.indexOf(
      items.find(item => item.id === parseInt(input.id))
    );
    items.splice(index, 1, {
      id: parseInt(input.id),
      name: input.name,
      isChecked: input.checked
    });

    this.setState({ checklistItemVOsGen: items });
  };

  handleVehChecklistItemChange = ({ currentTarget: input }) => {
    let items = [...this.state.checklistItemVOsVeh];

    const index = items.indexOf(
      items.find(item => item.id === parseInt(input.id))
    );
    items.splice(index, 1, {
      id: parseInt(input.id),
      name: input.name,
      isChecked: input.checked
    });

    this.setState({ checklistItemVOsVeh: items });
  };

  doSubmit = async () => {
    try {
      const { status } = await logShiftChecklist(this.state.data, [
        ...this.state.checklistItemVOsGen,
        ...this.state.checklistItemVOsVeh
      ]);
      if (status >= 200 && status < 300) {
        toast.success("Checklist has been logged!");
        this.props.history.push("/incidents");
      }
    } catch (ex) {
      toast.error("Action failed - " + ex);
      this.setState({ disableOnClick: false });
    }
  };

  render() {
    const { isUserSupervisor } = this.state;

    return (
      <React.Fragment>
        <div className="h1 mb-3">{this.props.label}</div>
        <form onSubmit={this.handleSubmit}>
          <div className="border pl-3 pr-3 pb-3 mb-3">
            <h3 className="mt-3">{"General Checklist"}</h3>
            <div className="row">
              <div className="col-4">
                {this.renderDatetime("logDatetime", "Date & Time")}
              </div>
              <div className="col-4">
                {isUserSupervisor &&
                  this.renderSelect(
                    "reporterId1",
                    "Driver 1",
                    this.state.reporters1
                  )}
                {!isUserSupervisor &&
                  this.renderInput(
                    "reporterName1",
                    "Driver 1",
                    !isUserSupervisor
                  )}
              </div>
              <div className="col-4">
                {this.renderSelect(
                  "reporterId2",
                  "Driver 2",
                  this.state.reporters2
                )}
              </div>
            </div>
            <div>
              <Checkboxes
                label="Checklist Items"
                items={this.state.checklistItemVOsGen}
                onChange={this.handleEqChecklistItemChange}
                columns={3}
              />
            </div>

            <div className="row mt-3">
              <div className="col">
                {this.renderTextarea(
                  "commentGen",
                  "Report the missing/broken equipment(s) for replacement",
                  2
                )}
              </div>
            </div>
          </div>

          <div className="border pl-3 pr-3 pb-3 mb-2">
            <h3 className="mt-3">{"Vehicle Checklist"}</h3>
            <div className="row mt-2">
              <div className="col-4">{this.renderInput("unit", "Unit")}</div>
              <div className="col-4">
                {this.renderInput("odometer", "Odometer")}
              </div>
            </div>
            <div>
              <Checkboxes
                label="Checklist Items"
                items={this.state.checklistItemVOsVeh}
                onChange={this.handleVehChecklistItemChange}
                columns={3}
              />
            </div>

            <div className="row mt-3">
              <div className="col">
                {this.renderTextarea(
                  "commentVeh",
                  "Report the missing/broken part of vehicle(s) for replacement",
                  2
                )}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                {this.renderInput("email", "Email the report to")}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-3 mb-3">
            {this.renderButton("Save")}
            <Link
              to="/incidents"
              className="btn btn-warning ml-auto"
              onClick={e =>
                this.state.disableOnClick ? e.preventDefault() : true
              }
            >
              <span className="fa fa-times mr-2" />
              {"Cancel"}
            </Link>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default ChecklistForm;
