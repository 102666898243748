import React from "react";
import FileUpload from "./fileUpload";

const ImgUpload = ({ label, raiseFilesUpdate }) => {
  return (
    <FileUpload
      label={label}
      raiseFilesUpdate={raiseFilesUpdate}
      acceptedFileTypes={["image/*"]}
      labelFileTypeNotAllowed="File of invalid type"
    />
  );
};

export default ImgUpload;
