import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import ProtectedRoute from "./common/protectedRoute";
import Categories from "./categories/categories";
import ChangePasswordForm from "./users/changePasswordForm";
import ChecklistEnd from "./checklists/checklistEnd";
import ChecklistStart from "./checklists/checklistStart";
import Incidents from "./incidents/incidents";
import IncidentForm from "./incidents/incidentForm";
import LoginForm from "./auth/loginForm";
import Logout from "./auth/logout";
import PageNotFound from "./pageNotFound";
import RptIncientsDetails from "./reports/rptIncidentsDetails";
import RptCategorySummary from "./reports/rptCategorySummary";
import RptCommunitySummary from "./reports/rptCommunitySummary";
import RptPartiesSummary from "./reports/rptPartiesSummary";
import NavBarLeft from "./navBarLeft";
import UserForm from "./users/userForm";
import Users from "./users/users";

const MainContainer = ({ user }) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <NavBarLeft user={user} />

        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
          {/* className="container" */}
          <Switch>
            <Route path="/login" component={LoginForm} />
            <Route path="/logout" component={Logout} />
            <ProtectedRoute path="/users/:id" component={UserForm} />
            <ProtectedRoute path="/myProfile/:id" component={UserForm} />
            <ProtectedRoute
              path="/changePassword/:userId"
              component={ChangePasswordForm}
            />
            <ProtectedRoute path="/users" component={Users} />
            <ProtectedRoute path="/categories" component={Categories} />
            <ProtectedRoute path="/incidents/:id" component={IncidentForm} />
            <ProtectedRoute path="/incidents" component={Incidents} />
            <ProtectedRoute
              path="/checklists/start"
              component={ChecklistStart}
            />
            <ProtectedRoute path="/checklists/end" component={ChecklistEnd} />
            <ProtectedRoute
              path="/rpt/community-summary"
              component={RptCommunitySummary}
            />
            <ProtectedRoute
              path="/rpt/category-summary"
              component={RptCategorySummary}
            />
            <ProtectedRoute
              path="/rpt/parties-summary"
              component={RptPartiesSummary}
            />
            <ProtectedRoute
              path="/rpt/incidents-details/:selectedCommunityId?/:selectedIncidentTypeId?/:selectedMonthId?"
              component={RptIncientsDetails}
            />
            <Redirect from="/" exact to="/incidents" />
            <Route path="/not-found" component={PageNotFound} />
            <Redirect to="/not-found" />
            {/* <Route path="" render={props=> <Movies {...props} user={user}/>} */}
          </Switch>
        </main>
      </div>
    </div>
  );
};

export default MainContainer;
