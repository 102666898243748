import React, { Component } from "react";
import Radio from "../common/radio";
import Select from "../common/select";
import Datetime from "../common/datetime";
import Pagination from "../common/pagination";
import RptDetailsTable from "./rptDetailsTable";
import { getAllCommunities } from "../../services/communityService";
import { getAllIncidentTypes } from "../../services/incidentTypeService";
import { getIncidentsDetailsRptData } from "../../services/reportsService";
import {
  populateMonthSelectors,
  populateStartAndEndDateBySelectedMonth
} from "../../utils/datetimeUtil.js";
import { paginate, sort } from "../../utils/datatableUtils";
import { generateReport } from "../../utils/pdfGenerator";
import { rptParamDateFormat, defaultTblPageSize } from "../../config.json";

const DEFAULT_ID = 0;
class RptIncidentsDetails extends Component {
  state = {
    incidentsDetailsRptData: [],
    allIncidentTypes: [],
    allCommunities: [],
    currentPage: 0,
    pageSize: defaultTblPageSize,
    tableHeaders: [
      "Description",
      "Action Taken",
      "House Info.",
      "Date & Time",
      "Reporter",
      "Category",
      "Community"
    ],
    sortColumn: { path: "incidentDateTime", order: "asc" }
  };

  async componentDidMount() {
    let {
      selectedCommunityId,
      selectedIncidentTypeId,
      selectedMonthId
    } = this.props.match.params;

    selectedCommunityId = selectedCommunityId
      ? selectedCommunityId
      : DEFAULT_ID;
    selectedIncidentTypeId = selectedIncidentTypeId
      ? selectedIncidentTypeId
      : DEFAULT_ID;
    selectedMonthId = selectedMonthId ? selectedMonthId : new Date().getMonth();

    const allCommunities = await this.populateCommunities();
    const allIncidentTypes = await this.populateIncidentTypes();

    const {
      selectedStartDate,
      selectedEndDate
    } = populateStartAndEndDateBySelectedMonth(
      selectedMonthId,
      populateMonthSelectors()
    );

    const incidentsDetailsRptData = await this.populateRptData(
      selectedCommunityId,
      selectedIncidentTypeId,
      selectedStartDate,
      selectedEndDate
    );

    this.setState({
      allCommunities,
      allIncidentTypes,
      incidentsDetailsRptData,
      selectedCommunityId,
      selectedIncidentTypeId,
      selectedStartDate,
      selectedEndDate
    });
  }

  async populateRptData(
    communityId,
    selectedIncidentTypeId,
    startDate,
    endDate
  ) {
    const { data } = await getIncidentsDetailsRptData(
      communityId,
      selectedIncidentTypeId,
      startDate,
      endDate
    );
    return data;
  }

  async populateIncidentTypes() {
    const { data } = await getAllIncidentTypes();
    return [{ id: 0, name: "All Categories" }, ...data];
  }

  async populateCommunities() {
    const { data } = await getAllCommunities();
    return [{ id: 0, name: "All" }, ...data];
  }

  handlePageChange = event => {
    this.setState({ currentPage: event.page });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleCommunityChange = async ({ currentTarget: input }) => {
    const selectedCommunityId = Number.parseInt(input.value);
    const {
      selectedIncidentTypeId,
      selectedStartDate,
      selectedEndDate
    } = this.state;

    const incidentsDetailsRptData = await this.populateRptData(
      selectedCommunityId,
      selectedIncidentTypeId,
      selectedStartDate,
      selectedEndDate
    );

    this.setState({
      selectedCommunityId,
      incidentsDetailsRptData,
      currentPage: 0
    });
  };

  handleCategoryChange = async ({ currentTarget: input }) => {
    const selectedIncidentTypeId = Number.parseInt(input.value);
    const {
      selectedCommunityId,
      selectedStartDate,
      selectedEndDate
    } = this.state;

    const incidentsDetailsRptData = await this.populateRptData(
      selectedCommunityId,
      selectedIncidentTypeId,
      selectedStartDate,
      selectedEndDate
    );

    this.setState({
      selectedIncidentTypeId,
      incidentsDetailsRptData,
      currentPage: 0
    });
  };

  handleChangeDate = async (moment, name) => {
    const { selectedCommunityId, selectedIncidentTypeId } = this.state;

    let { selectedStartDate, selectedEndDate } = this.state;

    if (name === "startDate")
      selectedStartDate = moment.format(rptParamDateFormat);
    else if (name === "endDate")
      selectedEndDate = moment.format(rptParamDateFormat);

    const incidentsDetailsRptData = await this.populateRptData(
      selectedCommunityId,
      selectedIncidentTypeId,
      selectedStartDate,
      selectedEndDate
    );

    this.setState({
      selectedStartDate,
      selectedEndDate,
      incidentsDetailsRptData,
      currentPage: 0
    });
  };

  handleExportClick = async () => {
    const {
      incidentsDetailsRptData,
      tableHeaders,
      selectedStartDate,
      selectedEndDate,
      sortColumn
    } = this.state;

    const dataSorted = sort(incidentsDetailsRptData, sortColumn);
    const exportData = [];

    for (let i = 0; i < incidentsDetailsRptData.length; i++) {
      let lst = [];
      lst.push(dataSorted[i].description);
      lst.push(dataSorted[i].actionTaken);
      lst.push(dataSorted[i].additionalInformation);
      lst.push(dataSorted[i].incidentDateTime);
      lst.push(dataSorted[i].reporter.name);
      lst.push(dataSorted[i].incidentType.name);
      lst.push(dataSorted[i].community.name);
      exportData.push(lst);
    }

    generateReport(
      "Incidents Detail Report",
      `${selectedStartDate} - ${selectedEndDate}`,
      tableHeaders,
      exportData,
      ["*", "*", "auto", "auto", "auto", "auto", "auto"]
    );
  };

  getPagedData = () => {
    const {
      incidentsDetailsRptData,
      pageSize,
      currentPage,
      sortColumn
    } = this.state;

    const dataSorted = sort(incidentsDetailsRptData, sortColumn);
    const dataPerPage = paginate(dataSorted, currentPage, pageSize);

    return { totalCount: incidentsDetailsRptData.length, data: dataPerPage };
  };

  render() {
    const {
      allIncidentTypes,
      allCommunities,
      selectedStartDate,
      selectedEndDate,
      selectedIncidentTypeId,
      pageSize,
      currentPage,
      sortColumn,
      selectedCommunityId
    } = this.state;

    const { totalCount, data } = this.getPagedData();
    const columns = [
      {
        path: "description",
        label: this.state.tableHeaders[0]
      },
      {
        path: "actionTaken",
        label: this.state.tableHeaders[1]
      },
      {
        path: "additionalInformation",
        label: this.state.tableHeaders[2]
      },
      { path: "incidentDateTime", label: this.state.tableHeaders[3] },
      {
        path: "reporter.name",
        label: this.state.tableHeaders[4]
      },
      { path: "incidentType.name", label: this.state.tableHeaders[5] }
    ];

    if (selectedCommunityId === 0) {
      columns.push({
        path: "community.name",
        label: this.state.tableHeaders[6]
      });
    }

    return (
      <React.Fragment>
        <div className="h2">
          <span className="fa fa-file-text mr-3" />
          Incidents Details Report
        </div>
        <div className="row border m-2">
          <div className="col-6 mt-3">
            <Radio
              name={"communityId"}
              value={selectedCommunityId}
              label={"Community"}
              options={allCommunities}
              onChange={this.handleCommunityChange}
              styleClass={"radio-button-sm"}
            />
          </div>
          <div className="col-2 mt-3">
            <Select
              name={"id"}
              value={selectedIncidentTypeId}
              label={"Category"}
              options={allIncidentTypes}
              onChange={this.handleCategoryChange}
            />
          </div>

          <div className="col-4">
            <div className="row mr-3 mt-3">
              <div className="col-6">
                <Datetime
                  name={"selectedStartDate"}
                  value={selectedStartDate}
                  label={"Start Date"}
                  viewMode={"days"}
                  timeFormat={false}
                  onChange={moment =>
                    this.handleChangeDate(moment, "startDate")
                  }
                />
              </div>
              <div className="col-6">
                <Datetime
                  name={"selectedEndDate"}
                  value={selectedEndDate}
                  label={"End Date"}
                  viewMode={"days"}
                  timeFormat={false}
                  onChange={moment => this.handleChangeDate(moment, "endDate")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="border m-2 p-2">
          <RptDetailsTable
            id="tblRptIncidentsDetails"
            columns={columns}
            data={data}
            sortColumn={sortColumn}
            onLike={this.handleLike}
            onSort={this.handleSort}
          />

          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
            raiseExportClick={this.handleExportClick}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default RptIncidentsDetails;
