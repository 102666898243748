import React from "react";

const Textarea = ({ name, label, rows = 5, error, ...rest }) => {
  return (
    <div className="mb-2">
      <label htmlFor={name}>{label}</label>
      <textarea name={name} rows={rows} {...rest} className="form-control" />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Textarea;
