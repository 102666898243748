import React from "react";
import { NavLink } from "react-router-dom";

const NavItem = ({
  label,
  isActive = false,
  isVisible = true,
  linkTo,
  icon,
  styleClass = "mr-2"
}) => {
  return (
    isVisible && (
      <li className={`nav-item ${isActive}`}>
        <NavLink className="nav-link" to={linkTo}>
          <span className={`fa fa-${icon} ${styleClass}`} />
          {label}
        </NavLink>
      </li>
    )
  );
};

export default NavItem;
