import React, { Component } from "react";
import CategoriesTable from "./categoriesTable";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/datatableUtils";
import { Link } from "react-router-dom";
import { getAllUsersByCurrentUser } from "../../services/userService";
import _ from "lodash";

class Categories extends Component {
  state = {
    categories: [],
    currentPage: 0,
    pageSize: 10,
    sortColumn: { path: "name", order: "asc" }
  };

  async componentDidMount() {
    const { data: categories } = await getAllUsersByCurrentUser();
    this.setState({ categories });
  }

  handleDelete = user => {
    const usersFiltered = this.state.categories.filter(u => u.id !== user.id);
    this.setState({ categories: usersFiltered });
  };

  handlePageChange = event => {
    this.setState({ currentPage: event.page });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { pageSize, currentPage, categories: usersAll, sortColumn } = this.state;

    const usersSorted = _.orderBy(
      usersAll,
      [sortColumn.path],
      [sortColumn.order]
    );

    const usersPerPage = paginate(usersSorted, currentPage, pageSize);
    return { totalCount: usersSorted.length, data: usersPerPage };
  };

  render() {
    const { pageSize, currentPage, sortColumn } = this.state;
    const { totalCount, data: categories } = this.getPagedData();

    return (
      <React.Fragment>
        <h1>
          <span className="fa fa-users mr-3" />
          Categories
        </h1>
        <div className="d-flex">
          <Link to="/users/new" className="btn btn-success ml-auto mb-3">
            <span className="fa fa-plus-square mr-2" />
            New Category
          </Link>
        </div>

        <CategoriesTable
          categories={categories}
          sortColumn={sortColumn}
          onDelete={this.handleDelete}
          onSort={this.handleSort}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
      </React.Fragment>
    );
  }
}

export default Categories;
