import React, { Component } from "react";
import NavItem from "./common/navItem";

class NavBarLeft extends Component {
  render() {
    const { user } = this.props;

    // TODO update to use latest version of fontawesome, using react-fontawesome
    return (
      <nav className="col-md-2 d-none d-md-block navbar-left">
        <div className="navbar-left-sticky">
          {user.id > 0 && (
            <ul className="nav flex-column">
              <li className="nav-item mb-3">
                <span className="nav-label">
                  <span className="fa fa-list-alt mr-2" />
                  Incidents
                </span>
                <ul className="nav flex-column">
                  <NavItem
                    label="Latest Incidents"
                    linkTo="/incidents"
                    icon="list-alt"
                  />
                </ul>
                <ul className="nav flex-column">
                  <NavItem
                    label="Report New Incident"
                    linkTo="/incidents/new"
                    icon="plus-square"
                  />
                </ul>
              </li>
              <li className="nav-item mb-3">
                <span className="nav-label">
                  <span className="fa fa-th-list mr-2" />
                  Daily Checklists
                </span>
                <ul className="nav flex-column">
                  <NavItem
                    label="Shift Start"
                    linkTo="/checklists/start"
                    icon="list-ul"
                  />
                  <NavItem
                    label="Shift End"
                    linkTo="/checklists/end"
                    icon="list-ul"
                  />
                </ul>
              </li>
              {user.roleRank <= 10 && (
                <li className="nav-item mb-3">
                  <span className="nav-label">
                    <span className="fa fa-folder-open mr-2" />
                    Reports
                  </span>

                  <ul className="nav flex-column">
                    <NavItem
                      label="Community Summary"
                      isVisible={user.roleRank <= 10}
                      linkTo="/rpt/community-summary"
                      icon="bar-chart"
                    />
                    <NavItem
                      label="Category Summary"
                      isVisible={user.roleRank <= 10}
                      linkTo="/rpt/category-summary"
                      icon="bar-chart"
                    />
                    <NavItem
                      label="Parties Summary"
                      isVisible={user.roleRank <= 10}
                      linkTo="/rpt/parties-summary"
                      icon="bar-chart"
                    />
                    <NavItem
                      label="Incidents Details"
                      isVisible={user.roleRank <= 10}
                      linkTo="/rpt/incidents-details"
                      icon="file-text"
                    />
                  </ul>
                </li>
              )}
              {user.roleRank <= 10 && (
                <li className="nav-item mb-3">
                  <span className="nav-label">
                    <span className="fa fa-cog mr-2" />
                    Administration
                  </span>
                  <ul className="nav flex-column">
                    <NavItem
                      label="Users"
                      isVisible={user.roleRank <= 10}
                      linkTo="/users"
                      icon="group"
                    />
                    <NavItem
                      label="Categories"
                      isVisible={user.roleRank <= 10}
                      linkTo="/categories"
                      icon="group"
                    />
                  </ul>
                </li>
              )}
            </ul>
          )}
        </div>
      </nav>
    );
  }
}

export default NavBarLeft;
