// import Raven from "raven-js";

function init() {
//	Raven.config("ADD API KEY", {release: "1-0-0", environment: "development-test"}).install();
}

function log(error) {
  console.error(error);
  // Raven.captureException(error);
}

export default {
  init,
  log
};
