import React from "react";
import { Paginator } from "primereact/paginator";
import PropTypes from "prop-types";

const Pagination = ({
  itemsCount,
  pageSize,
  currentPage,
  onPageChange,
  raiseExportClick
}) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount <= 0) return "No Records Found.";
  // const totalPages = _.range(1, pagesCount + 1);

  return (
    <div>
      <div className="d-flex justify-content-between mb-5">
        <span>Total: {itemsCount}</span>
        {pagesCount > 1 && (
          <Paginator
            first={currentPage * pageSize}
            rows={pageSize}
            totalRecords={itemsCount}
            onPageChange={onPageChange}
            template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          />
        )}
        {raiseExportClick && (
          <button className="btn btn-danger" onClick={raiseExportClick}>
            <span className={`fa fa-file-pdf-o`} />
          </button>
        )}
      </div>
    </div>
  );
};

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default Pagination;
