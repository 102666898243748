import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../img/Logo.png";

class NavBarTop extends Component {
  render() {
    const { user } = this.props;

    return (
      <nav className="navbar navbar-top d-flex">
        <Link className="navbar-brand flex-grow-1" to="/">
          <img
            src={logo}
            width="50"
            height="50"
            alt="LLRIB - Support Services"
          />
          <span style={{ fontWeight: "bold", fontSize: 24, marginLeft: 30 }}>
            LLRIB
          </span>
        </Link>
        {user.id > 0 && (
          <React.Fragment>
            <span className="nav-item text-nowrap">
              <NavLink className="nav-link" to={`/myProfile/${user.id}`}>
                <span className="fa fa-user mr-2" />
                {user.name}
              </NavLink>
            </span>
            <span className="user-label"> > </span>
          </React.Fragment>
        )}

        <ul className="navbar-nav px-3">
          {user.id > 0 && (
            <li className="nav-item text-nowrap">
              <NavLink className="nav-link" to="/logout">
                <span className="fa fa-sign-out mr-2" />
                Sign out
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    );
  }
}

export default NavBarTop;
