import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";

const Table = ({ id = "tbl", columns, sortColumn, onSort, data }) => {
  return (
    <React.Fragment>
      <table id={id} className="table table-hover table-striped table-bordered">
        <TableHeader
          columns={columns}
          sortColumn={sortColumn}
          onSort={onSort}
        />
        <TableBody columns={columns} data={data} />
      </table>
    </React.Fragment>
  );
};

export default Table;
