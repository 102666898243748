import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { Link } from "react-router-dom";
import Moment from "moment";
import { toast } from "react-toastify";
import ImgUpload from "../common/imgUpload";
import ImgGallery from "../common/imgGallery";
import {
  getCurrentUserId,
  getCurrentUserName,
  getCurrentUserRoleRank
} from "../../services/authService";
import { getAllCommunities } from "../../services/communityService";
import { getIncident, saveIncident } from "../../services/incidentService";
import { getAllIncidentTypes } from "../../services/incidentTypeService";
import { getActiveUsersByCurrentUser } from "../../services/userService";
import { datetimeFormat } from "../../config.json";

class IncidentForm extends Form {
  constructor() {
    super();
    this.state = {
      data: this.initIncidentData(),
      isViewOnly: false,
      disableOnClick: false,
      incidentTypes: [],
      communities: [],
      reporters: [],
      images: [],
      errors: {}
    };
  }

  schema = {
    id: Joi.number(),
    incidentDateTime: Joi.date()
      .required()
      .label("Date & Time"),
    description: Joi.string()
      .required()
      .max(3000)
      .label("Description"),
    additionalInformation: Joi.string().allow(""),
    actionTaken: Joi.string()
      .required()
      .max(3000)
      .label("Action Taken"),
    incidentTypeId: Joi.number()
      .required()
      .label("Category"),
    communityId: Joi.number()
      .required()
      .label("Community"),
    reporterId: Joi.number().label("reporterId"),
    reporterName: Joi.string()
      .required()
      .label("Reporter"),
    latitude: Joi.number(),
    longitude: Joi.number()
    //   ,
    // hasUserCertified: Joi.bool()
    //   .valid(true)
    //   .options({
    //     language: { any: { allowOnly: "must be checked!" }, label: "Certify" }
    //   })
  };

  initIncidentData = () => {
    return {
      id: 0,
      incidentDateTime: Moment(new Date()).format(datetimeFormat),
      description: "",
      actionTaken: "",
      additionalInformation: "",
      incidentTypeId: 1,
      communityId: 1,
      reporterId: getCurrentUserId(),
      reporterName: getCurrentUserName(),
      latitude: 23.483728,
      longitude: 12.343256
      // hasUserCertified: true
    };
  };

  async componentDidMount() {
    const isUserSupervisor = getCurrentUserRoleRank() <= 10;
    let reporters = [];
    if (isUserSupervisor) {
      reporters = await this.populateReporters();
    }
    await this.populateIncidentTypes();
    await this.populateCommunities();
    await this.populateIncident();

    this.setState({ reporters, isUserSupervisor });
  }

  componentDidUpdate() {
    if (this.props.match.params.id === "new") {
      if (this.state.data.id === 0) return;
      this.setState({
        data: this.initIncidentData(),
        images: [],
        isViewOnly: false
      });
    }
  }

  async populateReporters() {
    const { data } = await getActiveUsersByCurrentUser();
    return [...data];
  }

  async populateCommunities() {
    const { data: communities } = await getAllCommunities();
    this.setState({ communities });
  }

  async populateIncidentTypes() {
    const { data: incidentTypes } = await getAllIncidentTypes();
    this.setState({ incidentTypes, incidentTypeId: incidentTypes[0].id });
  }

  async populateIncident() {
    const incidentId = this.props.match.params.id;
    if (incidentId === "new") return;

    try {
      const { data: incident } = await getIncident(incidentId);
      const incidentVO = this.mapToVO(incident);
      const { imagesNameList } = incident;
      console.log(imagesNameList);
      if (imagesNameList.length > 0) {
        let images = [];
        for (let i = 0; i < imagesNameList.length; i++) {
          const imgUri =
            process.env.REACT_APP_API_URL +
            "files/images/incidents/" +
            incidentVO.id +
            "/" +
            imagesNameList[i];

          images.push({ original: imgUri, thumbnail: imgUri });
        }
        this.setState({ data: incidentVO, images, isViewOnly: true });
      } else this.setState({ data: incidentVO, isViewOnly: true });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  mapToVO(incident) {
    return {
      id: incident.id,
      incidentDateTime: Moment(new Date(incident.incidentDateTime)).format(
        datetimeFormat
      ),
      description: incident.description,
      actionTaken: incident.actionTaken,
      additionalInformation: incident.additionalInformation,
      incidentTypeId: incident.incidentType.id,
      communityId: incident.community.id,
      reporterId: incident.reporter.id,
      reporterName: incident.reporter.name
    };
  }

  handleFilesUpdate = fileItems => {
    const images = fileItems.map(fileItem => fileItem.file);
    this.setState({
      images
    });
  };

  doSubmit = async () => {
    try {
      const { status } = await saveIncident(this.state.data, this.state.images);
      if (status >= 200 && status < 300) {
        this.state.data.id === 0
          ? toast.success("Incident has been reported!")
          : toast.success("Incident has been updated!");
        this.props.history.push("/incidents");
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
      else if (
        ex.response &&
        (ex.response.status === 400 || ex.response.status === 406)
      )
        toast.error(
          "Invalid Data! Please verify Incident date & time is in the past"
        );
      else toast.error("Action failed - " + ex.response.status);

      this.setState({ disableOnClick: false });
    }
  };

  render() {
    const { isUserSupervisor } = this.state;

    return (
      <React.Fragment>
        <div className="h1 mb-3">Incident Report Form</div>
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-6">
              {this.renderRadio(
                "communityId",
                "Community",
                this.state.communities
              )}
            </div>
            <div className="col-6">
              {this.renderDatetime("incidentDateTime", "Date & Time")}
            </div>
          </div>
          <div className="row" style={{ height: 190 }}>
            <div className="col-12">
              {this.renderRadio(
                "incidentTypeId",
                "Category",
                this.state.incidentTypes
              )}
            </div>
          </div>
          {this.renderTextarea("description", "Description", 10)}
          {parseInt(this.state.data.incidentTypeId) === 16 &&
            this.renderInput("additionalInformation", "House Address")}
          {this.renderTextarea("actionTaken", "Action Taken", 10)}

          {isUserSupervisor &&
            this.renderSelect("reporterId", "Reporter", this.state.reporters)}
          {!isUserSupervisor &&
            this.renderInput("reporterName", "Reporter", !isUserSupervisor)}

          {this.props.match.params.id === "new" ? (
            <ImgUpload
              label="Images2"
              raiseFilesUpdate={this.handleFilesUpdate}
            />
          ) : (
            this.state.images &&
            this.state.images.length > 0 && (
              <ImgGallery label="Images" images={this.state.images} />
            )
          )}

          {/* {this.renderCheck(
                "hasUserCertified",
                "i certify that the above information is true and correct to the best of my knowledge"
              )} */}

          {(this.state.data.id === 0 ||
            (this.state.data.id > 0 && isUserSupervisor)) && (
            <div className="d-flex justify-content-between mt-5 mb-3">
              {this.renderButton("Save")}
              <Link
                to="/incidents"
                className="btn btn-warning ml-auto"
                onClick={e =>
                  this.state.disableOnClick ? e.preventDefault() : true
                }
              >
                <span className="fa fa-times mr-2" />
                {isUserSupervisor ? "Cancel" : "Reset"}
              </Link>
            </div>
          )}
        </form>
      </React.Fragment>
    );
  }
}

export default IncidentForm;
