import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const ImgGallery = ({ label, images }) => {
  return (
    <div className="mt-2">
      <label>{label}</label>
      <ImageGallery items={images} />
    </div>
  );
};

export default ImgGallery;
