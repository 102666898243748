import _ from "lodash";

export function sort(data, sortColumn) {
  return _.orderBy(data, [sortColumn.path], [sortColumn.order]);
}

export function paginate(data, pageNumber, pageSize) {
  const startIndex = pageNumber * pageSize;
  return _(data)
    .slice(startIndex)
    .take(pageSize)
    .value();
}
