import { PieChart, Pie, Cell, Tooltip } from "recharts";
import React, { Component } from "react";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

class Piechart extends Component {
  state = {};
  render() {
    const { data, dataKey, nameKey } = this.props;
    return (
      <PieChart width={600} height={600} onMouseEnter={this.onPieEnter}>
        <Pie
          data={data}
          dataKey={dataKey}
          nameKey={nameKey}
          cx={300}
          cy={200}
          labelLine={false}
          label
          // label={renderCustomizedLabel}
          outerRadius={120}
          innerRadius={50}
          paddingAngle={1}
          fill="#8884d8"
        >
          {this.props.data.map((entry, dataIndex) => (
            <Cell key={dataIndex} fill={COLORS[dataIndex % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        {/* <Tooltip content={<CustomTooltip />} /> */}
      </PieChart>
    );
  }
}

export default Piechart;
