import React, { Component } from "react";
import MainContainer from "./components/mainContainer";
import NavBarTop from "./components/navBarTop";
import { ToastContainer } from "react-toastify";
import authService from "./services/authService";
import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./css/style.css";
import LoginSuccess from "./components/loginSuccess";

class App extends Component {
  state = {
    user: { id: 0, username: "", name: "", role: "", roleId: 0, roleRank: 0 }
  };

  async componentWillMount() {
    const { user } = this.state;
    if (user.id && user.id > 0) {
      // console.log("User already logged in and set in state");
      // console.log(user);
    } else {
      const userDetails = authService.getCurrentUserDetails();

      if (userDetails) {
        const {
          USER_ID,
          USERNAME,
          NAME,
          ROLE,
          ROLE_ID,
          ROLE_RANK
        } = userDetails;

        const user = {};
        user.id = USER_ID;
        user.username = USERNAME;
        user.name = NAME;
        user.role = ROLE;
        user.roleId = ROLE_ID;
        user.roleRank = ROLE_RANK;
        this.setState({ user });
      }
    }
  }

  render() {
    const { user } = this.state;
    if (parseInt(user.id) > 0 && parseInt(user.roleRank) === 0) {
      return <LoginSuccess username={user.username} />;
    } else {
      return (
        <React.Fragment>
          {/* <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          /> */}
          <ToastContainer />
          <NavBarTop user={user} />
          <MainContainer user={user} />
        </React.Fragment>
      );
    }
  }
}

export default App;
