import React from "react";

const Radio = ({
  name,
  label,
  value,
  options,
  styleClass,
  error,
  isVertical,
  ...rest
}) => {
  const style = styleClass ? styleClass : "radio-button";
  return options ? (
    <div className="form-group ">
      <label htmlFor={name}>{label}</label>
      <div
        className={`btn-group${
          isVertical ? "-vertical" : "-sm"
        } btn-group-toggle form-control`}
        data-toggle="buttons"
      >
        {options.map(option => (
          <label
            key={"lbl" + option.id}
            className={`btn mr-1 mb-1 btn-${
              value.toString() === option.id.toString()
                ? "success active"
                : "secondary"
            } ${style}`}
          >
            <input
              type="radio"
              key={option.id}
              value={option.id}
              name={name}
              title={name}
              data-toggle="tooltip"
              data-placement="top"
              {...rest}
            />
            {option.name}
          </label>
        ))}

        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </div>
  ) : (
    ""
  );
};

export default Radio;
