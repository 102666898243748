import { Component } from "react";
import auth from "../../services/authService";

class Logout extends Component {
  componentDidMount() {
    console.log("Logout is called");
    auth.logout();

    window.location = "/";
  }

  render() {
    console.log("Logout called");
    return null;
  }
}

export default Logout;
