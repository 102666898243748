import React from "react";
import Form from "../common/form";
import Checkbox from "../common/checkbox";
import Joi from "joi-browser";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import authService from "../../services/authService";
import { getAllCommunities } from "../../services/communityService";
import { getAllRoles } from "../../services/roleService";
import { getUser, saveUser, getMyProfile } from "../../services/userService";

class UserForm extends Form {
  schema = {
    id: Joi.number(),
    name: Joi.string()
      .required()
      .label("Name"),
    username: Joi.string()
      .required()
      .min(6)
      .max(30)
      .label("Username"),
    password: Joi.string()
      .required()
      // .regex(/^(?=.*[0-9])/, { name: "must contain a number" })
      .min(6)
      .max(20)
      .label("Password"),
    confirmPassword: Joi.string()
      .required()
      .min(6)
      .max(20)
      .label("Confirm Password"),
    email: Joi.string()
      .required()
      .email({ minDomainAtoms: 2 })
      .label("Email"),
    phone1: Joi.string()
      .trim()
      .regex(/^[0-9]{10}$/, { name: "phone" })
      .required()
      .label("Phone1"),
    phone2: Joi.string()
      .regex(/^[0-9]{10}$/, { name: "phone" })
      .allow("")
      .label("Phone2"),
    isActive: Joi.bool(),
    designation: Joi.string().allow(""),
    communityId: Joi.number(),
    communityName: Joi.string(),
    roleId: Joi.number(),
    roleName: Joi.string(),
    department: Joi.string(),
    departmentId: Joi.number()
  };

  constructor(props) {
    super(props);
    this.state = {
      data: {
        id: 0,
        name: "",
        username: "",
        password: "",
        confirmPassword: "",
        email: "",
        designation: "",
        roleId: 5,
        roleName: "Security Officer",
        department: "Security",
        departmentId: 3,
        communityId: 1,
        communityName: "c_name",
        phone1: "",
        phone2: "",
        isActive: true
      },
      isUserProfile:
        Number.parseInt(props.match.params.id) ===
        Number.parseInt(authService.getCurrentUserId()),
      roles: [],
      communities: [],
      errors: {}
    };
  }

  async componentDidMount() {
   await this.populateUser();
    if (!this.state.isUserProfile) {
      await this.populateRoles();
      await this.populateCommunities();
    }
  }

  async populateRoles() {
    const { data: roles } = await getAllRoles();
    this.setState({ roles });
  }

  async populateCommunities() {
    const { data: communities } = await getAllCommunities();
    this.setState({ communities });
  }

  async populateUser() {
    try {
      let userVO = null;
      if (this.state.isUserProfile) {
        const { data: user } = await getMyProfile();
        userVO = user;
      } else {
        const userId = this.props.match.params.id;
        if (userId === "new") return;

        const { data: user } = await getUser(userId);
        userVO = user;
      }
      this.setState({ data: this.mapToVO(userVO) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  mapToVO(user) {
    if (user != null) {
      console.log(user);
      return {
        id: user.id,
        name: user.name,
        username: user.username,
        password: "```````",
        confirmPassword: "```````",
        email: user.email ? user.email : "",
        phone1: user.phone1 ? user.phone1 : "",
        phone2: user.phone2 ? user.phone2 : "",
        designation: user.designation ? user.designation : "",
        communityId: user.communityId,
        communityName: user.communityName,
        department: user.department,
        departmentId: user.departmentId,
        roleId: user.roleId,
        roleName: user.roleName,
        isActive: user.isActive
      };
    } else {
      console.log("Error - User is empty.");
      return null;
    }
  }

  handleIsActiveChkBoxItemChange = ({ currentTarget: input }) => {
    let { data } = this.state;
    data.isActive = input.checked;

    this.setState({ data });
  };

  doSubmit = async () => {
    const { password, confirmPassword } = this.state.data;
    if (password !== confirmPassword) {
      this.setState({ errors: ["Passwords don't match"] });
      toast.error("Passwords don't match!");
    } else {
      try {
        const { status } = await saveUser(this.state.data);
        if (status >= 200 && status < 300) {
          toast.success("Action successfull!");
          this.props.history.push("/users");
        }
      } catch (ex) {
        if (ex.response && ex.response.status === 404)
          this.props.history.replace("/not-found");
        else if (
          ex.response &&
          (ex.response.status === 400 || ex.response.status === 409)
        )
          toast.error("Duplicate record - username or email already exist");
        else toast.error("Action failed - " + ex.response.status);
      }
    }
  };

  render() {
    const { isUserProfile } = this.state;
    const { id: currentUserId, isActive } = this.state.data;

    return (
      <div>
        <h1>
          <span className="fa fa-user mr-3" />
          {isUserProfile ? "My Profile" : "User Information"}
        </h1>
        {isUserProfile && (
          <div className="d-flex">
            <Link
              to={`/changePassword/${currentUserId}`}
              className="btn btn-primary ml-auto mb-3"
            >
              <span className="fa fa-key mr-2" />
              Change Password
            </Link>
          </div>
        )}

        <div className="border p-3 mt-1 mb-3">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    {this.renderInput("name", "Name")}
                  </div>
                  <div className="col-6">
                    {this.renderInput(
                      "username",
                      "Username",
                      currentUserId !== 0
                    )}
                  </div>
                </div>
                {currentUserId === 0 && (
                  <div className="row">
                    <div className="col-6">
                      {this.renderInput(
                        "password",
                        "Password",
                        false,
                        "password"
                      )}
                    </div>
                    <div className="col-6">
                      {this.renderInput(
                        "confirmPassword",
                        "Confirm Password",
                        false,
                        "password"
                      )}
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-6">
                    {this.renderInput("email", "Email")}
                  </div>
                  <div className="col-6">
                    {this.renderInput("designation", "Designation")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    {this.renderInput("phone1", "Phone1")}
                  </div>
                  <div className="col-6">
                    {this.renderInput("phone2", "Phone2")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    {isUserProfile
                      ? this.renderInput("roleName", "Role", true)
                      : this.renderSelect("roleId", "Role", this.state.roles)}
                  </div>

                  <div className="col-6">
                    {isUserProfile
                      ? this.renderInput("communityName", "Community", true)
                      : this.renderRadio(
                          "communityId",
                          "Community",
                          this.state.communities
                        )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    {this.renderInput("department", "Department", true)}
                  </div>
                  <div className="col-6">
                    {/* {this.renderInput("subDepartment", "Sub Department", true)} */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Checkbox
                        id={1}
                        name="Active"
                        checked={isActive}
                        onChange={this.handleIsActiveChkBoxItemChange}
                      />
                  </div>
                  <div className="col-6">
                   
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex mt-2">
              {this.renderButton("Save")}
              <Link to="/users" className="btn btn-warning ml-auto">
                <span className="fa fa-times mr-2" />
                Cancel
              </Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default UserForm;
