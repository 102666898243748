import React from "react";
import Checkbox from "./checkbox";

const Checkboxes = ({ label, items, onChange, columns }) => {
  // TODO: refactor in future, make it dynamic to divide into multiple arrays
  // const disectIndex = items.length / columns;
  const colItemsCount = Math.ceil(items.length / 4);
  const items1 = items.slice(0, colItemsCount);
  const items2 = items.slice(colItemsCount, colItemsCount * 2);
  const items3 = items.slice(colItemsCount * 2, colItemsCount * 3);
  const items4 = items.slice(colItemsCount * 3, items.length);
  return (
    <React.Fragment>
      <label>{label}</label>
      <div className="row">
        <div className="col-3">
          {items1 &&
            items1.map(item => (
              <div key={item.id}>
                <Checkbox
                  id={item.id}
                  key={item.id}
                  name={item.name}
                  checked={item.isChecked}
                  onChange={onChange}
                />
              </div>
            ))}
        </div>

        <div className="col-3">
          {items2 &&
            items2.map(item => (
              <div key={item.id}>
                <Checkbox
                  id={item.id}
                  key={item.id}
                  name={item.name}
                  checked={item.isChecked}
                  onChange={onChange}
                />
              </div>
            ))}
        </div>

        <div className="col-3">
          {items3 &&
            items3.map(item => (
              <div key={item.id}>
                <Checkbox
                  id={item.id}
                  key={item.id}
                  name={item.name}
                  checked={item.isChecked}
                  onChange={onChange}
                />
              </div>
            ))}
        </div>

        <div className="col-3">
          {items4 &&
            items4.map(item => (
              <div key={item.id}>
                <Checkbox
                  id={item.id}
                  key={item.id}
                  name={item.name}
                  checked={item.isChecked}
                  onChange={onChange}
                />
              </div>
            ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Checkboxes;
