import http from "./httpService";

const apiEndpoint = "checklist";

export function getAllChecklistItems() {
  return http.get(`${apiEndpoint}`);
}

export function getChecklistItemsByType(type) {
  return http.get(`${apiEndpoint}/${type}`);
}

export function logShiftChecklist(checklistLog, checklistItemVOs) {
  checklistLog.checklistItemVOs = checklistItemVOs;
  return http.post(apiEndpoint, checklistLog);
}
