import React from "react";

const Checkbox = ({ id, name, error, ...rest }) => {
  return (
    <div className="form-check form-check-inline mb-1">
      <input
        id={id}
        name={name}
        type="checkbox"
        className="form-check-input"
        {...rest}
      />
      <label htmlFor={id} className="form-check-label">
        {name}
      </label>

      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Checkbox;
