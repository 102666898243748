import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function generateReport(
  reportLabel,
  reportTimePeriod,
  tableHeaders,
  tableData,
  tableColWidths
) {
  let totalIncidentsCount = 0;

  let tableBody = [];

  // Table column widths
  let widths = ["auto"];
  for (let colWidth of tableColWidths) {
    widths.push(colWidth);
  }

  // Table Header
  let header = ["Sr. No."];
  let incidentCountColNum,
    colNum = -1;

  for (let colHeader of tableHeaders) {
    header.push({ text: `${colHeader}`, style: "tableHeader" });
    colNum += 1;
    if (colHeader === "Incident Count") {
      incidentCountColNum = colNum;
    }
  }
  tableBody.push(header);

  // Table Data
  let rowNum = 0;
  for (let dataRow of tableData) {
    colNum = -1;
    let tableRow = [++rowNum];
    for (let col of dataRow) {
      tableRow.push(col);
      colNum += 1;
      if (colNum === incidentCountColNum) {
        totalIncidentsCount += col;
      }
    }
    tableBody.push(tableRow);
  }
  if (totalIncidentsCount <= 0) {
    totalIncidentsCount = rowNum;
  }

  // PDF document definition for PdfMake
  let docDef = {
    info: {
      title: reportLabel,
      author: "LLRIB - Support Services System",
      subject: reportLabel
    },
    // pageSize: 'A4',
    pageOrientation: widths.length > 5 ? "landscape" : "portrait",
    header: function(currentPage, pageCount, pageSize) {
      return [
        {
          columns: [
            // { image: "logo.png" },
            {
              text: "LLRIB",
              style: "pageHeader"
            },
            {
              text: reportLabel + " (" + reportTimePeriod + ")",
              style: "pageHeader",
              alignment: "right"
            }
          ]
        }
      ];
    },
    content: [
      { text: reportLabel, style: "heading1" },
      {
        text: `${reportTimePeriod} (Total Incidents: ${totalIncidentsCount})`,
        style: "heading2"
      },
      {
        table: {
          headerRows: 1,
          widths: widths,
          body: tableBody
        },
        layout: {
          fillColor: function(rowIndex, node, columnIndex) {
            return rowIndex === 0
              ? "#AAAAAA"
              : rowIndex % 2 === 0
              ? "#DDDDDD"
              : null;
          }
        }
      }
    ],
    footer: function(currentPage, pageCount) {
      return [
        {
          text: currentPage.toString() + " of " + pageCount,
          style: "pageFooter"
        }
      ];
    },
    styles: {
      pageHeader: {
        fontSize: 10,
        color: "#AAAAAA",
        margin: [20, 10, 20, 0]
      },
      pageFooter: {
        fontSize: 10,
        margin: [0, 10, 20, 0],
        alignment: "right"
      },
      heading1: {
        fontSize: 18,
        bold: true,
        alignment: "center",
        margin: [0, 0, 0, 5]
      },
      heading2: {
        fontSize: 13,
        alignment: "center",
        margin: [0, 10, 0, 15]
      },
      tableHeader: {
        alignment: "center",
        bold: true
      }
    }
  };
  pdfMake.createPdf(docDef).open();
  // pdfMake.createPdf(docDef).download(reportLabel + " " + reportTimePeriod);
}
