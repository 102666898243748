import Moment from "moment";
import { rptParamDateFormat } from "../config.json";

export function populateMonthSelectors() {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  let monthNumArr = [];
  let month = new Date().getMonth();
  let year = new Date().getFullYear();
  for (let index = 0; index < 3; index++) {
    monthNumArr.push({ month, year });
    if (monthNumArr[index].month - 1 < 0) {
      month = 11;
      year = year - 1 < new Date().getFullYear() - 1 ? year : year - 1;
    } else {
      month = monthNumArr[index].month - 1;
    }
  }

  let monthsArr = [];
  monthNumArr.map(m =>
    monthsArr.push({ id: m.month, name: months[m.month], year: m.year })
  );
  monthsArr.push({ id: 12, name: "Last Three", year: 0 });
  return monthsArr;
}

export function populateStartAndEndDateByYearAndMonth(selectedMonth) {
  let year = selectedMonth ? selectedMonth.year : new Date().getFullYear(),
    month = selectedMonth ? selectedMonth.id : new Date().getMonth();

  let startDate = Moment(new Date(year, month, 1)).format(rptParamDateFormat);
  let endDate = Moment(new Date(year, month + 1, 0)).format(rptParamDateFormat);

  return { startDate, endDate };
}

export function getMonthById(monthsArr, monthId) {
  return monthsArr.find(
    m => Number.parseInt(m.id) === Number.parseInt(monthId)
  );
}

export function populateStartAndEndDateBySelectedMonth(
  selectedMonthId,
  filterMonthsArr
) {
  let selectedStartDate = "",
    selectedEndDate = "";
  if (selectedMonthId < 12) {
    const selectedMonth = getMonthById(filterMonthsArr, selectedMonthId);
    const { startDate, endDate } = populateStartAndEndDateByYearAndMonth(
      selectedMonth
    );
    selectedStartDate = startDate;
    selectedEndDate = endDate;
  } else {
    let selectedMonth = getMonthById(filterMonthsArr, filterMonthsArr[2].id);
    const { startDate } = populateStartAndEndDateByYearAndMonth(selectedMonth);
    selectedStartDate = startDate;

    selectedMonth = getMonthById(filterMonthsArr, filterMonthsArr[0].id);
    const { endDate } = populateStartAndEndDateByYearAndMonth(selectedMonth);
    selectedEndDate = endDate;
  }

  return { selectedStartDate, selectedEndDate };
}
