import axios from "axios";
import logger from "./logService";
import { toast } from "react-toastify";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(null, error => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    logger.log(error);
    toast.error("An unexpected error occurrred.");
  }

  return Promise.reject(error);
});

function setJwt(jwt) {
  if (jwt) axios.defaults.headers.common["x-authorization"] = `Bearer ${jwt}`;
}

function postWithFiles(url, incident, files) {
  let formData = new FormData();

  formData.append(
    "incident",
    new Blob([JSON.stringify(incident)], {
      type: "application/json"
    })
  );

  // RN
  // formData.append("incident", JSON.stringify(incident));

  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }

  return axios.post(url, formData);
}
export default {
  get: axios.get,
  post: axios.post,
  postWithFiles,
  put: axios.put,
  delete: axios.delete,
  setJwt
};
