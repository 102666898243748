import React from "react";
import Joi from "joi-browser";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Form from "../common/form";
import { updatePassword } from "../../services/userService";

class ChangePasswordForm extends Form {
  state = {
    data: {
      userId: this.props.match.params.userId,
      passwordOld: "",
      passwordNew: "",
      confirmPasswordNew: ""
    },
    errors: {}
  };

  schema = {
    userId: Joi.number(),
    passwordOld: Joi.string()
      .required()
      .min(6)
      .max(20)
      .label("Old Password"),
    passwordNew: Joi.string()
      .required()
      .min(6)
      .max(20)
      .label("New Password"),
    confirmPasswordNew: Joi.string()
      .required()
      .min(6)
      .max(20)
      .label("Confirm New Password")
  };

  async componentWillMount() {}

  doSubmit = async () => {
    const { passwordNew, confirmPasswordNew } = this.state.data;
    if (passwordNew !== confirmPasswordNew) {
      this.setState({ errors: ["Passwords don't match"] });
      toast.error("Passwords don't match!");
    } else {
      try {
        const { status } = await updatePassword(this.state.data);
        if (status >= 200 && status < 300) {
          toast.success("Password has been updated!");
          this.props.history.push("/users");
        }
      } catch (ex) {
        if (ex.response && ex.response.status === 404)
          this.props.history.replace("/not-found");
        else if (
          ex.response &&
          (ex.response.status === 400 || ex.response.status === 409)
        )
          toast.error("Old password is not correct");
        else toast.error("Action failed - " + ex.response.status);
      }
    }
  };

  render() {
    const { userId } = this.state.data;
    return (
      <div>
        <h3>Change Password</h3>
        <form onSubmit={this.handleSubmit} className="border p-3">
          {this.renderInput("passwordOld", "Old Password", false, "password")}
          {this.renderInput("passwordNew", "New Password", false, "password")}
          {this.renderInput(
            "confirmPasswordNew",
            "Confirm Password",
            false,
            "password"
          )}

          <div className="d-flex mt-3">
            {this.renderButton("Update")}
            <Link to={`/users/${userId}`} className="btn btn-warning ml-auto">
              <span className="fa fa-times mr-2" />
              Cancel
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

export default ChangePasswordForm;
