import React from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import Form from "../common/form";
import auth from "../../services/authService";

class LoginForm extends Form {
  state = {
    data: { username: "", password: "" },
    errors: {}
  };

  schema = {
    username: Joi.string()
      .required()
      .label("Username"),
    password: Joi.string()
      .required()
      .label("Password")
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;
      await auth.login(data.username, data.password);

      const { state } = this.props.location;
      window.location = state ? state.from.pathname : "/";
    } catch (ex) {
      if (ex.response) {
        // console.log(ex.response.data);
        this.setState({ disableOnClick: false });
        if (ex.response.status === 400) {
          const errors = { ...this.state.errors };
          errors.username = ex.response.data;
          this.setState({ errors });
          toast.error("Bad Request!");
        } else if (ex.response.status === 401) {
          toast.error("Username or Password not correct!");
        } else if (ex.response.status === 423) {
          toast.error("This User is locked, please contact administrator!");
        }
      }
    }
  };

  render() {
    // TODO: Handle error handling at common place
    return (
      <div>
        <h1>Login</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("username", "Username")}
          {this.renderInput("password", "Password", false, "password")}
          <button
            disabled={this.validate() ? true : false}
            className="btn btn-success"
          >
            <span className={`fa fa-sign-in mr-2`} />
            Login
          </button>
        </form>
      </div>
    );
  }
}

export default LoginForm;
