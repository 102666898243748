import http from "./httpService";

const rptApiEndpoint = "reports";

export function getCommunitySummaryRptData(communityId, startDate, endDate) {
  return http.get(
    rptApiEndpoint + `/communitysummary/${communityId}/${startDate}/${endDate}`
  );
}

export function getPartiesSummaryRptData(communityId, startDate, endDate) {
  return http.get(
    rptApiEndpoint + `/partiessummary/${communityId}/${startDate}/${endDate}`
  );
}

export function getIncidnetTypeSummaryRptData(
  incidentTypeId,
  startDate,
  endDate
) {
  return http.get(
    rptApiEndpoint +
      `/categorysummary/${incidentTypeId}/${startDate}/${endDate}`
  );
}

export function getIncidentsDetailsRptData(
  communityId,
  incidentTypeId,
  startDate,
  endDate
) {
  return http.get(
    rptApiEndpoint +
      `/incidentsdetails/${communityId}/${incidentTypeId}/${startDate}/${endDate}`
  );
}
