import Radio from "../common/radio";
import React, { Component } from "react";
import Pagination from "../common/pagination";
import RptSummaryTable from "./rptSummaryTable";
import Piechart from "../charts/Piechart";
import { getAllCommunities } from "../../services/communityService";
import { getPartiesSummaryRptData } from "../../services/reportsService";
import {
  populateMonthSelectors,
  populateStartAndEndDateBySelectedMonth
} from "../../utils/datetimeUtil.js";
import { paginate, sort } from "../../utils/datatableUtils";
import { generateReport } from "../../utils/pdfGenerator";
import { defaultTblPageSize } from "../../config.json";

class RptPartiesSummary extends Component {
  state = {
    partySummaryRptData: [],
    currentPage: 0,
    pageSize: defaultTblPageSize,
    selectedCommunityId: 0,
    selectedMonthId: new Date().getMonth(),
    tableHeaders: ["Community", "House Information", "Incident Count"],
    sortColumn: { path: "incidentCount", order: "desc" }
  };

  async componentDidMount() {
    const allCommunities = await this.populateCommunities();
    const filterMonthsArr = populateMonthSelectors();
    const {
      selectedStartDate,
      selectedEndDate
    } = populateStartAndEndDateBySelectedMonth(
      this.state.selectedMonthId,
      filterMonthsArr
    );
    const partySummaryRptData = await this.populateReportData(
      this.state.selectedCommunityId,
      selectedStartDate,
      selectedEndDate
    );
    this.setState({
      allCommunities,
      filterMonthsArr,
      partySummaryRptData,
      selectedStartDate,
      selectedEndDate
    });
  }

  async populateCommunities() {
    const { data } = await getAllCommunities();
    return [{ id: 0, name: "All" }, ...data];
  }

  async populateReportData(
    selectedCommunityId,
    selectedStartDate,
    selectedEndDate
  ) {
    const { data } = await getPartiesSummaryRptData(
      selectedCommunityId,
      selectedStartDate,
      selectedEndDate
    );
    return data;
  }

  handlePageChange = event => {
    this.setState({ currentPage: event.page });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleCommunityChange = async ({ currentTarget: input }) => {
    const { selectedStartDate, selectedEndDate } = this.state;
    const selectedCommunityId = Number.parseInt(input.value);

    const partySummaryRptData = await this.populateReportData(
      selectedCommunityId,
      selectedStartDate,
      selectedEndDate
    );
    this.setState({
      partySummaryRptData,
      selectedCommunityId,
      currentPage: 0
    });
  };

  handleMonthChange = async ({ currentTarget: input }) => {
    const selectedMonthId = Number.parseInt(input.value);

    const {
      selectedStartDate,
      selectedEndDate
    } = populateStartAndEndDateBySelectedMonth(
      selectedMonthId,
      this.state.filterMonthsArr
    );

    const partySummaryRptData = await this.populateReportData(
      this.state.selectedCommunityId,
      selectedStartDate,
      selectedEndDate
    );

    this.setState({
      partySummaryRptData,
      selectedMonthId,
      selectedStartDate,
      selectedEndDate,
      currentPage: 0
    });
  };

  handleExportClick = async () => {
    const {
      partySummaryRptData,
      tableHeaders,
      selectedStartDate,
      selectedEndDate,
      sortColumn
    } = this.state;

    const dataSorted = sort(partySummaryRptData, sortColumn);
    const exportData = [];

    for (let i = 0; i < partySummaryRptData.length; i++) {
      let lst = [];
      lst.push(dataSorted[i].communityName);
      lst.push(dataSorted[i].additionalInformation);
      lst.push(dataSorted[i].incidentCount);
      exportData.push(lst);
    }

    generateReport(
      "Parties Summary Report",
      `${selectedStartDate} - ${selectedEndDate}`,
      tableHeaders,
      exportData,
      ["auto", "*", "auto"]
    );
  };

  getPagedData = () => {
    const {
      partySummaryRptData,
      pageSize,
      currentPage,
      sortColumn
    } = this.state;

    const dataSorted = sort(partySummaryRptData, sortColumn);
    const dataPerPage = paginate(dataSorted, currentPage, pageSize);

    return { totalCount: partySummaryRptData.length, data: dataPerPage };
  };

  render() {
    const {
      partySummaryRptData,
      pageSize,
      currentPage,
      sortColumn,
      selectedCommunityId
    } = this.state;

    const { totalCount, data } = this.getPagedData();

    const columns = [
      {
        path: "additionalInformation",
        label: this.state.tableHeaders[1]
      },
      {
        path: "incidentCount",
        label: this.state.tableHeaders[2]
      }
    ];

    if (selectedCommunityId === 0) {
      columns.splice(0, 0, {
        path: "communityName",
        label: this.state.tableHeaders[0]
      });
    }

    return (
      <React.Fragment>
        <div className="h2">
          <span className="fa fa-bar-chart mr-3" />
          Report - Parties Summary
        </div>
        <div className="row border">
          <div className="col-6 mt-3">
            <Radio
              name={"communityId"}
              value={this.state.selectedCommunityId}
              label={"Community"}
              options={this.state.allCommunities}
              onChange={this.handleCommunityChange}
            />
          </div>
          <div className="col-6 mt-3">
            <Radio
              name={"id"}
              value={this.state.selectedMonthId}
              label={"Month"}
              options={this.state.filterMonthsArr}
              onChange={this.handleMonthChange}
            />
          </div>
        </div>
        <div className="row border mt-2">
          <div className="col-6 mt-3 mb-2">
            <RptSummaryTable
              columns={columns}
              data={data}
              sortColumn={sortColumn}
              onLike={this.handleLike}
              onSort={this.handleSort}
            />

            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
              raiseExportClick={this.handleExportClick}
            />
          </div>
          <div className="col-6">
            <Piechart
              data={partySummaryRptData}
              dataKey="incidentCount"
              nameKey="additionalInformation"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RptPartiesSummary;
